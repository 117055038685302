<template>
  <section class="section" id="editar-orcamento">
    <form ref="form" class="container-admin" @submit="handleSubmit">
      <b-loading :active.sync="isLoading" is-full-page />
      <div class="columns is-multiline">
        <div class="column is-full">
          <h3 class="title is-3">
            <router-link to="/admin/gerenciar-orcamentos" class="has-text-grey">
              Gerenciamento de Orçamentos
            </router-link>
            >
            <span class="has-text-primary">
              {{ isNew ? 'Cadastrar Orçamento' : 'Editar Orcamento' }}
            </span>
          </h3>
        </div>

        <b-field label="Nome" class="column is-half">
          <b-input v-model="orcamento.nome" type="text" name="nome" required />
        </b-field>
        <b-field label="Sobrenome" class="column is-half">
          <b-input v-model="orcamento.sobrenome" type="text" name="sobrenome" required />
        </b-field>
        <b-field label="CPF" class="column is-full">
          <b-input v-model="orcamento.cpf" type="text" name="cpf" required />
        </b-field>
        <b-field label="Email" class="column is-full">
          <b-input v-model="orcamento.email" type="email" name="email" required />
        </b-field>
        <b-field label="Celular" class="column is-full">
          <b-input v-model="orcamento.telefone" type="tel" name="telefone" required />
        </b-field>
        <b-field label="Período" class="column is-full">
          <b-datepicker v-model="orcamento.dataEntrada" icon="calendar-today" trap-focus expanded required @input="handleChangeDataEntrada">
          </b-datepicker>
          <b-datepicker v-model="orcamento.dataSaida" icon="calendar-today" trap-focus expanded required @input="handleChangeDataSaida">
          </b-datepicker>
        </b-field>
        <b-field class="column is-one-quarter" label="Qtd. Adultos">
          <b-input v-model="orcamento.quantidadeAdultos" type="number" required />
        </b-field>

        <b-field class="column is-one-quarter" label="Qtd. de Crianças (0 a 2 anos)">
          <b-input v-model="orcamento.quantidadeCriancasColos" type="number" required />
        </b-field>
        <b-field class="column is-one-quarter" label="Qtd. de Crianças (3 a 6 anos)">
          <b-input v-model="orcamento.quantidadeCriancasNaoPagantes" type="number" required />
        </b-field>
        <b-field class="column is-one-quarter" label="Qtd. de Crianças (7 as 12 anos)">
          <b-input v-model="orcamento.quantidadeCriancas" type="number" required />
        </b-field>
        <div class="column">
          <b-field label="Pagamento Autorizado">
            <b-checkbox v-model="orcamento.pagamentoAutorizado" type="is-success">
              {{ orcamento.pagamentoAutorizado ? 'Sim' : 'Não' }}
            </b-checkbox>
          </b-field>
        </div>

        <div class="column is-full">
          <hr />
        </div>

        <b-field class="column is-full" label="Hotel" v-if="isNew">
          <b-select v-model="orcamento.hotelId" expanded @input="handleSelectHotel">
            <option v-for="hotel in hoteis" :key="hotel.id" :value="hotel.id">
              {{ hotel.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field class="column is-full" label="Parque">
          <b-select v-model="orcamento.parqueId" expanded>
            <option v-for="parque in parques" :key="parque.id" :value="parque.id">
              {{ parque.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field class="column is-full" label="Tipo de Apartamento (Solicitado)">
          <b-select v-model="orcamento.tarifaTipoId" expanded required @input="handleChangeTarifaTipo">
            <option v-for="tarifaTipo in tarifasTipo" :key="tarifaTipo.id" :value="tarifaTipo.id">
              {{ tarifaTipo.nome }}
            </option>
          </b-select>
        </b-field>

        <b-field class="column is-full" label="Tarifa">
          <b-select v-model="orcamento.tarifaId" expanded required :loading="isLoadingTarifas" @input="handleChangeTarifa">
            <option v-for="tarifa in tarifas" :key="tarifa.id" :value="tarifa.id">
              {{ tarifa.nome }}
            </option>
          </b-select>
        </b-field>

        <div class="column is-full">
          <hr />
        </div>

        <div class="column is-full">
          <h3 class="title is-4 has-text-primary">
            Hospedagem
          </h3>
        </div>

        <div class="column is-full">
          <strong>
            {{ labelCheckInCheckOut }}
          </strong>
        </div>

        <div class="column is-full" v-if="tarifaSelecionada">
          <b-message type="is-danger">
            A quantidade mínima de diárias para essa tarifa é
            {{ tarifaSelecionada.minDiaria }} dia(s).
          </b-message>
        </div>

        <div class="column is-full" v-for="(hospedagem, index) in orcamento.hospedagens" :key="index">
          <b-field grouped>
            <b-field
              label="Data"
              :message="hospedagem.indisponivel ? 'Indispoível para essa tarifa' : null"
              :type="hospedagem.indisponivel ? 'is-danger' : 'is-success'"
            >
              <b-datepicker v-model="hospedagem.data" required icon="calendar-today" trap-focus expanded disabled> </b-datepicker>
            </b-field>
            <b-field label="Valor Diária">
              <b-input v-model="hospedagem.valor" required icon="currency-usd" type="number" expanded step="0.01" @input="onChangeValorHospedagem">
              </b-input>
              <p class="control">
                <b-button type="is-secondary" @click="handleDeleteHospedagem(index)">
                  Excluir
                </b-button>
              </p>

              <b-tooltip label="Atualizar para valor cadastrado" type="is-black">
                <b-button
                  icon-left="refresh"
                  type="is-success"
                  class="ml-2"
                  @click="handleAtualizarValorCadastrado(hospedagem)"
                  :disabled="!orcamento.tarifaId"
                ></b-button>
              </b-tooltip>
            </b-field>
          </b-field>
        </div>

        <div class="column is-full">
          <strong>Valor Total</strong>:
          <span class="has-text-danger" v-if="!valorTotalHospedagem">
            Informe os valores da hospedagem para calcular o total
          </span>
          <span v-else>{{ valorTotalHospedagem | maskCurrency }}</span>
        </div>

        <div class="column is-full">
          <hr />
        </div>

        <div class="column is-full">
          <h3 class="title is-4 has-text-primary">
            Ingressos
          </h3>
        </div>

        <div class="column is-full">
          <b-switch class="mt-2" v-model="hasIngressos" type="is-success" @input="handleChangeHasIngressos">
            Incluir Ingresso
          </b-switch>
        </div>

        <template v-if="hasIngressos">
          <div class="column is-full" v-for="(ingresso, index) in orcamento.ingressos" :key="`ingresso-${index}`">
            <b-field grouped>
              <b-field label="Data">
                <b-datepicker
                  v-model="ingresso.data"
                  icon="calendar-today"
                  trap-focus
                  expanded
                  required
                  :min-date="orcamento.dataEntrada"
                  :max-date="orcamento.dataSaida"
                  style="width: 150px"
                >
                </b-datepicker>
              </b-field>
              <b-field label="Parque">
                <b-select v-model="ingresso.parqueId" expanded required>
                  <option v-for="parque in parques" :key="parque.id" :value="parque.id">
                    {{ parque.nome }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Tipo">
                <b-select v-model="ingresso.tipo" expanded required @input="handleChangeTipoIngresso(ingresso)">
                  <option value="infantil">Infantil</option>
                  <option value="meia">Meia</option>
                  <option value="inteira">Inteira</option>
                </b-select>
              </b-field>
              <b-field label="Quantidade">
                <b-input
                  v-model="ingresso.quantidade"
                  style="width: 110px"
                  type="number"
                  step="1"
                  required
                  @input="handleChangeQuantidadeIngresso(ingresso)"
                />
              </b-field>
              <b-field label="Valor Unitário">
                <b-input v-model="ingresso.valorUnitario" type="number" step="0.01" required readonly style="width: 120px" />
              </b-field>
              <b-field label="Valor Total">
                <b-input v-model="ingresso.valorTotal" type="number" step="0.01" disabled required />
                <p class="control">
                  <b-button type="is-secondary" icon-left="trash-can" @click="handleDeleteIngresso(index)"></b-button>
                  <b-button class="ml-3" type="is-secondary" @click="handleAddIngresso(index, ingresso)" icon="plus">
                    Adicionar
                  </b-button>
                </p>
              </b-field>
            </b-field>
          </div>

          <div class="column is-full">
            <strong>Valor Total</strong>:
            <span class="has-text-danger" v-if="!valorTotalIngressos">
              Informe os valores dos ingressos para calcular o total
            </span>
            <span v-else>{{ valorTotalIngressos | maskCurrency }}</span>
          </div>
        </template>

        <div class="column is-full">
          <hr />
        </div>

        <div class="column is-full has-text-centered">
          <h2 class="title is-2 mb-0"><strong>Valor Total</strong>: {{ valorTotal | maskCurrency }}</h2>
          <small v-if="!valorTotal" class="has-text-danger">
            Informe todos os dados para calcular o total
          </small>
        </div>

        <div class="column is-full">
          <hr />
        </div>

        <div class="column is-one-fifth" v-if="!isNew">
          <a :href="linkOmnibees" class="button is-secondary is-fullwidth" target="_blank">
            Abrir Site do Hotel
          </a>
        </div>
        <div class="column is-one-fifth" v-if="!isNew">
          <b-button type="is-secondary" expanded :disabled="!valorTotal" @click="abrirModalConfirmarEnvioEmail">
            Enviar Email
          </b-button>
        </div>
        <div class="column is-one-fifth" v-if="!isNew">
          <b-button type="is-secondary" expanded @click="openModalEnvioWhats">
            Enviar Whats
          </b-button>
        </div>
        <div class="column is-one-fifth" v-if="!isNew">
          <b-button type="is-secondary" expanded disabled>Enviar SMS</b-button>
        </div>
        <div class="column is-one-fifth" v-if="!isNew">
          <b-button type="is-secondary" expanded :disabled="!valorTotal" @click="imprimir">
            Download
          </b-button>
        </div>
        <div class="column is-one-third">
          <b-button v-if="!isNew" type="is-primary" :disabled="orcamento.respondido" expanded @click="handleClickSalvarEMarcarRespondida">
            Salvar e Marcar Respondida
          </b-button>
        </div>
        <div class="column is-one-third">
          <b-button ref="buttonSubmit" tag="button" native-type="submit" label="Salvar" type="is-primary" expanded />
        </div>
        <div class="column is-one-third">
          <router-link to="/admin/gerenciar-orcamentos" class="button is-dark is-fullwidth">
            Sair
          </router-link>
        </div>
      </div>
    </form>

    <ModalEnvioWhats :orcamento="orcamento" :slug-hotel="slugHotel" :active="isModalEnvioWhatsActive" @close="closeModalEnvioWhats" />

    <PdfOrcamento
      ref="pdfOrcamento"
      :orcamento="orcamento"
      :parques="parques"
      :tarifas-tipo="tarifasTipo"
      :tarifas="tarifas"
      :has-ingressos="hasIngressos"
      :valor-total-hospedagem="valorTotalHospedagem"
      :valor-total-ingressos="valorTotalIngressos"
      :valor-total="valorTotal"
    />

    <ModalVendaOrcamento :active="isModalVendaOrcamentoActive" :orcamento="orcamento" :slug-hotel="slugHotel" @close="closeModalVendaOrcamento" />

    <ModalConfirmar
      title="Confirmar Envio de Email"
      message="Você deseja confirmar o envio do orçamento para o e-mail do cliente?"
      :ativo="isModalConfirmEnvioEmailActive"
      hide-default-message
      @cancel="() => (isModalConfirmEnvioEmailActive = false)"
      @confirm="sendMailOrcamento"
    />

    <ModalConfirmar
      message="Você deseja confirmar a exclusão desta diária?"
      :ativo="isModalConfirmDeleteHospedagemActive"
      @cancel="() => (isModalConfirmDeleteHospedagemActive = false)"
      @confirm="deleteHospedagem"
    />
  </section>
</template>

<script>
import dayjs from 'dayjs';
import ModalVendaOrcamento from '@/components/Cadastros/Orcamento/ModalVendaOrcamento.vue';
import ModalEnvioWhats from '@/components/Cadastros/Orcamento/ModalEnvioWhats.vue';
import PdfOrcamento from '@/components/Cadastros/Orcamento/PdfOrcamento.vue';
import ModalConfirmar from '@/components/ModalConfirmar.vue';
import tarifaTipoService from '@/services/tarifaTipoService';
import ingressosService from '@/services/ingressosService';
import orcamentoService from '@/services/orcamentoService';
import hoteisService from '@/services/hoteisService';
import parqueService from '@/services/parqueService';
import tarifaService from '@/services/tarifaService';

export default {
  name: 'EditOrcamento',
  components: {
    ModalEnvioWhats,
    PdfOrcamento,
    ModalVendaOrcamento,
    ModalConfirmar,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingTarifas: false,
      isModalVendaOrcamentoActive: false,
      isModalConfirmDeleteHospedagemActive: false,
      isModalEnvioWhatsActive: false,
      isModalConfirmEnvioEmailActive: false,
      hasIngressos: false,
      hotel: null,
      trashIndexHospedagem: null,
      hoteis: [],
      orcamento: {},
      tarifasTipo: [],
      tarifas: [],
      parques: [],
      ingressosPorParque: {},
    };
  },
  computed: {
    isNew() {
      return !this.id;
    },
    valorTotalHospedagem() {
      if (this.orcamento?.hospedagens?.some(h => !h.valor)) return null;

      return this.orcamento?.hospedagens?.reduce((accumulator, hospedagem) => {
        return accumulator + Number(hospedagem.valor);
      }, 0);
    },
    valorTotalIngressos() {
      if (this.orcamento?.ingressos?.some(h => !h.valorTotal)) return null;

      return this.orcamento?.ingressos?.reduce((accumulator, ingresso) => {
        return accumulator + Number(ingresso.valorTotal);
      }, 0);
    },
    valorTotal() {
      if (this.hasIngressos) {
        if (!this.valorTotalIngressos) return null;
        if (!this.valorTotalHospedagem) return null;

        return this.valorTotalIngressos + this.valorTotalHospedagem;
      } else {
        if (!this.valorTotalHospedagem) return null;

        return this.valorTotalHospedagem;
      }
    },
    slugHotel() {
      return this.hotel?.slug;
    },
    linkOmnibees() {
      const hotelId = this.hotel?.omnibeesId;
      const dataI = dayjs(this.orcamento?.dataEntrada).format('DDMMYYYY');
      const dataF = dayjs(this.orcamento?.dataSaida).format('DDMMYYYY');

      return `https://book.omnibees.com/hotelresults?c=6366&q=${hotelId}&hotel_folder=&NRooms=1&CheckIn=${dataI}&CheckOut=${dataF}&ad=1&ch=&ag=&group_code=&Code=&loyalty_code=&lang=pt-BR&currencyId=16`;
    },
    tarifaSelecionada() {
      if (this.orcamento.tarifaId) {
        return this.tarifas.find(t => t.id === this.orcamento.tarifaId);
      }

      return null;
    },
    labelCheckInCheckOut() {
      const { dataEntrada, dataSaida } = this.orcamento;
      const maskDate = this.$options.filters.maskDate;
      return `
        Check-In: ${maskDate(dataEntrada)} | Check-Out: ${maskDate(dataSaida)}
      `;
    },
  },
  async created() {
    if (this.isNew) {
      this.getHoteis();
      this.getParques();
    } else {
      this.isLoading = true;

      orcamentoService
        .getById(this.id)
        .then(async ({ data }) => {
          const orcamento = data;
          delete orcamento.dataCadastro;
          delete orcamento.dataAlteracao;
          orcamento.dataEntrada = new Date(dayjs(data.dataEntrada).format());
          orcamento.dataSaida = new Date(dayjs(data.dataSaida).format());

          await this.getHotelById(data.hotelId);
          await this.getParques();
          await this.searchTarifasTipo(data.hotelId);

          if (data.parqueId) {
            this.getIngressosValores(data);
          }

          if (data.tarifaTipoId) {
            await this.searchTarifas(data.tarifaTipoId, data.dataEntrada, data.dataSaida);
          }

          if (!data.hospedagens || !data.hospedagens.length) {
            const hospedagens = this.criarOrcamentoHospedagemInicial(orcamento);
            orcamento.hospedagens = hospedagens;
          } else {
            orcamento.hospedagens = data.hospedagens.map(hospedagem => {
              hospedagem.data = new Date(dayjs(hospedagem.data).format());
              return hospedagem;
            });
          }

          if (data.ingressos?.length) {
            this.hasIngressos = true;

            orcamento.ingressos = data.ingressos
              .map(ingresso => {
                ingresso.data = new Date(dayjs(ingresso.data).format());
                return ingresso;
              })
              .sort((a, b) => a.data - b.data);
          }

          this.orcamento = orcamento;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {
    getHoteis() {
      hoteisService.get().then(({ data }) => {
        this.hoteis = data;
      });
    },
    getHotelById(id) {
      hoteisService.getById(id).then(({ data }) => {
        this.hotel = data;
      });
    },
    getParques() {
      parqueService.get().then(({ data }) => {
        this.parques = data;
      });
    },
    handleChangeTarifaTipo(tarifaTipoId) {
      this.orcamento.tarifaId = null;

      if (tarifaTipoId) {
        const { dataEntrada, dataSaida } = this.orcamento;
        this.searchTarifas(tarifaTipoId, dataEntrada, dataSaida);
      }
    },
    searchTarifasTipo(hotelId) {
      tarifaTipoService.search({ hotelId, ativo: true }).then(({ data }) => {
        this.tarifasTipo = data;
      });
    },
    searchTarifas(tarifaTipoId, dataEntrada, dataSaida) {
      this.isLoadingTarifas = true;
      tarifaService
        .searchTarifasByTipoAndPeriodo({
          tarifaTipoId,
          dataI: dayjs(dataEntrada).format('YYYY-MM-DD'),
          dataF: dayjs(dataSaida).format('YYYY-MM-DD'),
        })
        .then(({ data }) => {
          this.tarifas = data;
        })
        .finally(() => {
          this.isLoadingTarifas = false;
        });
    },
    criarOrcamentoHospedagemInicial(orcamento) {
      const dataInicial = new Date(dayjs(orcamento.dataEntrada).format());
      const dataFinal = new Date(dayjs(orcamento.dataSaida).format());

      const hospedagens = [];

      for (let day = dataInicial; day < dataFinal; day.setDate(day.getDate() + 1)) {
        const hospedagem = {
          data: new Date(day),
          tarifaId: orcamento.tarifaId,
          orcamentoId: orcamento.id,
        };

        if (this.tarifaSelecionada?.periodos?.length) {
          const periodoReferente = this.tarifaSelecionada.periodos.find(p => p.data === dayjs(day).format('YYYY-MM-DD'));

          if (periodoReferente) {
            hospedagem.valor = periodoReferente.valor;
          }
        }

        hospedagens.push(hospedagem);
      }

      return hospedagens;
    },
    onChangeValorHospedagem() {
      this.orcamento.hospedagens = [...this.orcamento.hospedagens];
    },
    handleChangeTarifa(tarifaId) {
      if (this.isNew && !this.orcamento.hospedagens) {
        const hospedagens = this.criarOrcamentoHospedagemInicial(this.orcamento);
        this.orcamento.hospedagens = hospedagens;
        this.orcamento = { ...this.orcamento };
      }

      this.orcamento.hospedagens.forEach(hospedagem => {
        hospedagem.tarifaId = tarifaId;

        if (this.tarifaSelecionada?.periodos?.length) {
          const periodoReferente = this.tarifaSelecionada.periodos.find(p => p.data === dayjs(hospedagem.data).format('YYYY-MM-DD'));

          if (periodoReferente) {
            hospedagem.valor = periodoReferente.valor;
          } else {
            hospedagem.valor = null;
            hospedagem.indisponivel = true;
          }
        } else {
          hospedagem.valor = null;
          hospedagem.indisponivel = true;
        }
      });

      this.onChangeValorHospedagem();
    },
    criarOrcamentoIngressosInicial() {
      const dataInicial = new Date(dayjs(this.orcamento.dataEntrada).format());
      const dataFinal = new Date(dayjs(this.orcamento.dataSaida).format());

      const ingressos = [];

      for (let day = dataInicial; day < dataFinal; day.setDate(day.getDate() + 1)) {
        ingressos.push({
          data: new Date(day),
          parqueId: this.orcamento.parqueId,
          orcamentoId: this.orcamento.id,
        });
      }

      return ingressos;
    },
    handleChangeHasIngressos(newValue) {
      if (newValue && !this.orcamento?.ingressos?.length) {
        this.orcamento.ingressos = this.criarOrcamentoIngressosInicial();
      }
    },
    handleAddIngresso(index, ingresso) {
      const { data, parqueId, orcamentoId } = ingresso;

      this.orcamento.ingressos.splice(index + 1, 0, {
        data,
        parqueId,
        orcamentoId,
      });

      this.$buefy.snackbar.open('Ingresso adicionado');
    },
    getIngressosValores({ parqueId, dataEntrada, dataSaida }) {
      ingressosService
        .getIngressoPorRangeData({
          dataI: dayjs(dataEntrada).format('YYYY-MM-DD'),
          dataF: dayjs(dataSaida).format('YYYY-MM-DD'),
          parqueId,
        })
        .then(({ data }) => {
          this.ingressosPorParque[parqueId] = data;
        });
    },
    findValorIngressoByDataParqueETipo(data, parqueId, tipo) {
      const mappedTipos = {
        infantil: 'valorInfantil',
        meia: 'valorMeia',
        inteira: 'valorInteira',
      };

      const ingressosDoParque = this.ingressosPorParque[parqueId];

      if (ingressosDoParque) {
        const ingressoMesmaData = ingressosDoParque.find(i => i.data === data && i.quantidade > 0);

        if (ingressoMesmaData) return ingressoMesmaData[mappedTipos[tipo]];
      }
    },
    handleChangeTipoIngresso(ingresso) {
      const dataFormatada = dayjs(this.orcamento.dataEntrada).format('YYYY-MM-DD');

      const valorCorrespondente = this.findValorIngressoByDataParqueETipo(dataFormatada, ingresso.parqueId, ingresso.tipo);

      ingresso.valorUnitario = valorCorrespondente;
    },
    onChangeDadosIngressos() {
      this.orcamento = { ...this.orcamento };
    },
    calcularValorTotalIngressos(ingresso) {
      const { quantidade, valorUnitario } = ingresso;

      if (quantidade && valorUnitario) {
        ingresso.valorTotal = Number(quantidade) * Number(valorUnitario);
      } else {
        ingresso.valorTotal = null;
      }

      this.onChangeDadosIngressos();
    },
    handleChangeQuantidadeIngresso(ingresso) {
      this.calcularValorTotalIngressos(ingresso);
      this.onChangeDadosIngressos();
    },
    handleDeleteIngresso(index) {
      this.orcamento.ingressos.splice(index, 1);
      this.onChangeDadosIngressos();
    },
    async handleSubmit(event) {
      event.preventDefault();

      this.isLoading = true;

      if (this.isNew) {
        await orcamentoService.post(this.orcamento).then(({ data }) => {
          this.$router.push(`/admin/editar-orcamento/${data.id}`);
        });
      } else {
        await orcamentoService.put(this.orcamento);
      }

      this.$buefy.snackbar.open({
        duration: 3000,
        message: 'Orçamento salvo com sucesso',
        type: 'is-success',
        position: 'is-bottom-right',
        actionText: 'OK',
        queue: false,
      });

      this.isLoading = false;
    },
    handleClickSalvarEMarcarRespondida() {
      this.orcamento.respondido = true;
      this.$refs.buttonSubmit.$el.click();
    },
    openModalEnvioWhats() {
      this.isModalEnvioWhatsActive = true;
    },
    closeModalEnvioWhats() {
      this.isModalEnvioWhatsActive = false;
    },
    closeModalVendaOrcamento() {
      this.isModalVendaOrcamentoActive = false;
    },
    imprimir() {
      this.$refs.pdfOrcamento.generateReport();
    },
    handleSelectHotel(hotelId) {
      this.searchTarifasTipo(hotelId);
    },
    handleChangeDataEntrada() {
      if (this.isNew && this.orcamento.dataSaida && !this.orcamento.hospedagens) {
        const hospedagens = this.criarOrcamentoHospedagemInicial(this.orcamento);
        this.orcamento.hospedagens = hospedagens;
        this.orcamento = { ...this.orcamento };
      }
    },
    handleChangeDataSaida() {
      if (this.isNew && this.orcamento.dataEntrada && !this.orcamento.hospedagens) {
        const hospedagens = this.criarOrcamentoHospedagemInicial(this.orcamento);
        this.orcamento.hospedagens = hospedagens;
        this.orcamento = { ...this.orcamento };
      }
    },
    abrirModalConfirmarEnvioEmail() {
      this.isModalConfirmEnvioEmailActive = true;
    },
    sendMailOrcamento() {
      this.isModalConfirmEnvioEmailActive = false;

      orcamentoService.enviarEmail(this.orcamento.id).then(() => {
        this.$buefy.snackbar.open(`Email enviado com sucesso`);
      });
    },
    handleAtualizarValorCadastrado(hospedagem) {
      if (this.tarifaSelecionada) {
        const periodoReferente = this.tarifaSelecionada.periodos.find(p => p.data === dayjs(hospedagem.data).format('YYYY-MM-DD'));

        if (periodoReferente) {
          hospedagem.valor = periodoReferente.valor;
          this.orcamento = { ...this.orcamento };
          this.$buefy.snackbar.open({
            message: 'Valor atualizado com sucesso',
            type: 'is-success',
            queue: false,
            duration: 3000,
          });
        } else {
          this.$buefy.snackbar.open({
            message: 'Erro ao carregar período da tarifa selecionada',
            type: 'is-danger',
            queue: false,
            duration: 3000,
          });
        }
      }
    },
    handleDeleteHospedagem(index) {
      this.trashIndexHospedagem = index;
      this.isModalConfirmDeleteHospedagemActive = true;
    },
    deleteHospedagem() {
      this.orcamento.hospedagens.splice(this.trashIndexHospedagem, 1);
      this.isModalConfirmDeleteHospedagemActive = false;
      this.trashIndexHospedagem = null;
      this.orcamento = { ...this.orcamento };
      this.$buefy.snackbar.open({
        message: 'Diária excluída com sucesso',
        type: 'is-success',
        queue: false,
        duration: 3000,
      });
    },
  },
};
</script>
