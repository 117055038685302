import httpClient from './httpClient';
import httpAuthClient from './httpAuthClient';

const url = `orcamento`;

export default {
  getById(id) {
    return httpClient.get(`${url}/${id}`);
  },
  post(orcamento) {
    return httpClient.post(`${url}`, orcamento);
  },
  gerarVenda(id) {
    return httpClient.post(`${url}/GerarVenda/${id}`, {});
  },
  search({
    dataI,
    dataF,
    hotelId,
    respondido,
    convertido,
    usuarioId,
    nome,
    telefone,
    cpf,
  }) {
    let query = `/${url}/search?hotelId=${hotelId}&respondido=${respondido}&convertido=${convertido}`;

    if (dataI && dataF) {
      query = query.concat(`&dataI=${dataI}&dataF=${dataF}`);
    }

    if (usuarioId) {
      query = query.concat(`&usuarioId=${usuarioId}`);
    }

    if (nome) {
      query = query.concat(`&nome=${nome}`);
    }

    if (telefone) {
      query = query.concat(`&telefone=${telefone}`);
    }

    if (cpf) {
      query = query.concat(`&cpf=${cpf}`);
    }

    return httpAuthClient.get(query);
  },
  get() {
    return httpAuthClient.get(url);
  },
  put(orcamento) {
    return httpAuthClient.put(`/${url}`, orcamento);
  },
  enviarEmail(id) {
    return httpAuthClient.post(`/${url}/EnviarEmail/${id}`, {});
  },
};
