<template>
  <b-modal
    v-model="isModalActive"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-label="Modal venda criada"
    aria-modal
    destroy-on-hide
    :can-cancel="false"
  >
    <div class="modal-card">
      <section
        class="modal-card-body has-text-centered"
        style="border-radius: 8px"
      >
        <b-icon
          icon="check-circle"
          type="is-success"
          size="is-large"
          class="my-5"
        />
        <p>Número da Venda</p>
        <p class="is-size-3">#{{ orcamento.vendaId }}</p>
        <p>
          O link para pagamento desse orcamento é:
        </p>
        <div
          class="mb-4"
          style="display:flex;align-items: center; justify-content:center;"
        >
          <a :href="linkPagamento" target="_blank">{{ linkPagamento }}</a>
          <b-tooltip
            label="Copiar link"
            position="is-left"
            type="is-black"
            class="ml-2"
            style="cursor: pointer"
          >
            <b-icon icon="content-copy" @click.native="copyToClipboard" />
          </b-tooltip>
        </div>
        <b-button type="is-primary" @click="close">Fechar</b-button>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalVendaOrcamento',
  props: {
    orcamento: {
      type: Object,
      default: () => null,
    },
    slugHotel: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalActive: false,
    };
  },
  computed: {
    linkPagamento() {
      const { host, protocol } = window.location;

      return `${protocol}//${host}/${this.slugHotel}/pagamento/${this.orcamento.id}`;
    },
  },
  watch: {
    active(newValue) {
      this.isModalActive = newValue;
    },
    isModalActive(newValue) {
      if (!newValue) {
        this.close();
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    copyToClipboard() {
      if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData('Text', this.linkPagamento);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported('copy')
      ) {
        var textarea = document.createElement('textarea');
        textarea.textContent = this.linkPagamento;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.$buefy.snackbar.open('Link copiado com sucesso');
        } catch (ex) {
          // Engole exceção
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
  },
};
</script>
