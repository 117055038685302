import httpClient from './httpClient';
import httpAuthClient from './httpAuthClient';

const url = `tarifa`;

export default {
  searchTarifas({ hotelId, qtdAdultos, qtdInfantil }) {
    let query = `/${url}/searchTarifas?hotelId=${hotelId}`;

    if (qtdAdultos) {
      query = query.concat(`&qtdAdultos=${qtdAdultos}`);
    }

    if (qtdInfantil) {
      query = query.concat(`&qtdInfantil=${qtdInfantil}`);
    }

    return httpAuthClient.get(query);
  },
  getById(id) {
    return httpClient.get(`${url}/${id}`);
  },
  get() {
    return httpClient.get(url);
  },
  getTipos() {
    return httpClient.get(`${url}/tipos`);
  },
  search({ hotelId, tarifaTipoId, ativo }) {
    return httpAuthClient.get(
      `/${url}/search?hotelId=${hotelId}&tarifaTipoId=${tarifaTipoId}&ativo=${ativo}`,
    );
  },
  searchTarifasByTipoAndPeriodo({ tarifaTipoId, dataI, dataF }) {
    return httpAuthClient.get(
      `/${url}/searchTarifasByTipoAndPeriodo?tarifaTipoId=${tarifaTipoId}&dataI=${dataI}&dataF=${dataF}`,
    );
  },
  put(tarifa) {
    return httpAuthClient.put(`/${url}`, tarifa);
  },
  post(tarifa) {
    return httpAuthClient.post(`/${url}`, tarifa);
  },
  delete(id) {
    return httpAuthClient.delete(`/${url}/${id}`);
  },
};
