<template>
  <b-modal
    v-model="isModalActive"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-label="Modal enviar Whats"
    aria-modal
    destroy-on-hide
    :can-cancel="false"
  >
    <div class="modal-card">
      <section
        class="modal-card-body has-text-centered"
        style="border-radius: 8px"
      >
        <h4 class="title is-4 has-text-primary">Enviar Mensagem Whatsapp</h4>

        <hr />

        <b-input type="textarea" v-model="form.text" placeholder="Mensagem" />

        <hr />

        <div class="columns">
          <div class="column">
            <b-button type="is-light" expanded @click="close">
              Cancelar
            </b-button>
          </div>
          <div class="column">
            <b-button type="is-primary" expanded @click="enviarMensagem">
              Enviar
            </b-button>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalEnviarWhats',
  props: {
    slugHotel: {
      type: String,
      default: () => null,
    },
    orcamento: {
      type: Object,
      default: () => null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalActive: false,
      form: {},
    };
  },
  watch: {
    active(newValue) {
      this.isModalActive = newValue;

      if (newValue && this.orcamento?.vendaId) {
        const { host, protocol } = window.location;
        const linkPagamento = `${protocol}//${host}/${this.slugHotel}/pagamento/${this.orcamento?.id}`;
        this.form.text = `Segue o link para efetuar o pagamento: ${linkPagamento}`;
      }
    },
    isModalActive(newValue) {
      if (!newValue) {
        this.close();
      }
    },
  },
  methods: {
    close() {
      this.form = {};
      this.$emit('close');
    },
    enviarMensagem() {
      const telefone = '55' + this.orcamento.telefone;
      const msg = this.form.text || '';

      window.open(
        `https://api.whatsapp.com/send?phone=${telefone}&text=${msg}`,
        '_blank',
      );
    },
  },
};
</script>
