<template>
  <VueHtml2pdf
    ref="html2Pdf"
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="true"
    :paginate-elements-by-height="1100"
    :pdf-quality="2"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="700px"
    :manual-pagination="false"
    :html-to-pdf-options="htmlOpts"
  >
    <template #pdf-content class="section" id="pdf-orcamento">
      <h3 class="title is-3 has-text-primary has-text-centered">
        Orcamento #{{ orcamento.id }}
      </h3>

      <hr style="margin: 0" />

      <div class="columns is-multiline mt-5">
        <div class="column is-half"><b>Nome</b>: {{ orcamento.nome }}</div>
        <div class="column is-half">
          <b>Sobrenome</b> {{ orcamento.sobrenome }}
        </div>
        <div class="column is-half"><b>CPF</b> {{ orcamento.cpf }}</div>
        <div class="column is-half"><b>Email</b> {{ orcamento.email }}</div>
        <div class="column is-half">
          <b>Celular</b> {{ orcamento.telefone }}
        </div>
        <div class="column is-half">
          <b>Período</b>: {{ orcamento.dataEntrada | maskDate }} à
          {{ orcamento.dataSaida | maskDate }}
        </div>
        <div class="column is-half">
          <b>Qtd. Adultos</b>: {{ orcamento.quantidadeAdultos }}
        </div>
        <div class="column is-half">
          <b>Qtd. de Crianças (0 a 2 anos)</b>:
          {{ orcamento.quantidadeCriancasColos }}
        </div>
        <div class="column is-half">
          <b>Qtd. de Crianças (3 a 6 anos)</b>:
          {{ orcamento.quantidadeCriancasNaoPagantes }}
        </div>
        <div class="column is-half">
          <b>Qtd. de Crianças (7 as 12 anos)</b>:
          {{ orcamento.quantidadeCriancas }}
        </div>
      </div>

      <hr style="margin: 0" />

      <p class="mt-2 mb-2">
        <b>Tipo de Apartamento</b>: {{ nomeTipoApartamentoSolicitado }}
      </p>
      <p class="mb-2">
        <b>Detalhe do Apartamento</b>: {{ detalheTipoApartamentoSolicitado }}
      </p>
      <p class="mb-2">
        <b>Facilidades</b>: {{ facilidadesTipoApartamentoSolicitado }}
      </p>

      <p class="mb-2"><b>Tarifa</b>: {{ nomeTarifa }}</p>

      <hr style="margin: 0" />

      <h3 class="title is-3 has-text-primary mt-3 mb-4 has-text-centered">
        Hospedagens
      </h3>

      <p class="has-text-centered mb-3">
        <strong>{{ labelCheckInCheckOut }}</strong>
      </p>

      <div
        class="mb-2"
        v-for="(hospedagem, index) in orcamento.hospedagens"
        :key="index"
      >
        <b-field grouped class="group-hospedagem">
          <b-field label="Data" class="mr-6">
            {{ hospedagem.data | maskDate }}
          </b-field>
          <b-field label="Valor Diária">
            {{ hospedagem.valor | maskCurrency }}
          </b-field>
        </b-field>
      </div>

      <p class="my-2 has-text-centered is-size-4">
        <strong>Valor Total</strong>:
        {{ valorTotalHospedagem | maskCurrency }}
      </p>

      <hr style="margin: 0" v-if="hasIngressos" />

      <template v-if="hasIngressos">
        <h3 class="title is-3 has-text-primary my-3 has-text-centered">
          Ingressos
        </h3>
        <p class="my-3 has-text-centered">
          <b>Parque que deseja visitar</b>: {{ nomeDoParque }}
        </p>
        <div
          v-for="(ingresso, index) in orcamento.ingressos"
          :key="`ingresso-${index}`"
          class="ingressos-orcamento"
        >
          <b-field grouped class="columns">
            <b-field label="Data" class="column has-text-centered">
              {{ ingresso.data | maskDate }}
            </b-field>
            <b-field label="Parque" class="column has-text-centered">
              {{ getNomeParque(ingresso.parqueId) }}
            </b-field>
            <b-field
              label="Tipo"
              class="column has-text-centered is-capitalized"
            >
              {{ ingresso.tipo }}
            </b-field>
            <b-field label="Quantidade" class="column has-text-centered">
              {{ ingresso.quantidade }}
            </b-field>
            <b-field label="Valor Unitário" class="column has-text-centered">
              {{ ingresso.valorUnitario | maskCurrency }}
            </b-field>
            <b-field label="Valor Total" class="column has-text-centered">
              {{ ingresso.valorTotal | maskCurrency }}
            </b-field>
          </b-field>
        </div>

        <p class="my-3 has-text-centered is-size-4">
          <strong>Valor Total</strong>:
          {{ valorTotalIngressos | maskCurrency }}
        </p>
      </template>

      <hr style="margin: 0" />

      <h2 class="title is-2 my-4 has-text-centered">
        <strong>Valor Total</strong>: {{ valorTotal | maskCurrency }}
      </h2>

      <hr style="margin: 0" />
    </template>
  </VueHtml2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import facilidadesService from '@/services/facilidadeService';

export default {
  name: 'PdfOrcamento',
  components: {
    VueHtml2pdf,
  },
  props: {
    orcamento: {
      type: Object,
      default: null,
    },
    parques: {
      type: Array,
      default: () => [],
    },
    tarifasTipo: {
      type: Array,
      default: () => [],
    },
    tarifas: {
      type: Array,
      default: () => [],
    },
    hasIngressos: {
      type: Boolean,
      default: false,
    },
    valorTotalHospedagem: {
      type: Number,
      default: null,
    },
    valorTotalIngressos: {
      type: Number,
      default: null,
    },
    valorTotal: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      facilidades: [],
      htmlOpts: {
        margin: [10, 10, 10, 10],
        filename: `OrcamentoHospedagem_${this.orcamento.id}`,
        html2canvas: {
          scale: 1.2,
          bottom: 10,
          dpi: 300,
          letterRendering: true,
          useCORS: true,
        },
        image: { type: 'jpg', quality: 0.95 },
        pagebreak: { mode: ['avoid-all'] },
        jsPDF: {
          unit: 'mm',
          orientation: 'portrait',
        },
      },
    };
  },
  created() {
    facilidadesService.get().then(({ data }) => {
      this.facilidades = data;
    });
  },
  computed: {
    nomeDoParque() {
      const parque = this.parques.find(p => p.id === this.orcamento.parqueId);
      return parque?.nome;
    },
    tipoDeApartamentoSolicitado() {
      return this.tarifasTipo.find(t => t.id === this.orcamento.tarifaTipoId);
    },
    nomeTipoApartamentoSolicitado() {
      return this.tipoDeApartamentoSolicitado?.nome;
    },
    detalheTipoApartamentoSolicitado() {
      return this.tipoDeApartamentoSolicitado?.detalhe;
    },
    facilidadesTipoApartamentoSolicitado() {
      const facilidadesIds =
        this.tipoDeApartamentoSolicitado?.facilidades.map(
          f => f.facilidadeId,
        ) || [];

      const facilidades = this.facilidades.filter(
        f => facilidadesIds.indexOf(f.id) > -1,
      );

      return facilidades.map(f => f.nome).join(', ');
    },
    nomeTarifa() {
      const tarifa = this.tarifas.find(t => t.id === this.orcamento.tarifaId);
      return tarifa?.nome;
    },
    labelCheckInCheckOut() {
      const { dataEntrada, dataSaida } = this.orcamento;
      const maskDate = this.$options.filters.maskDate;
      return `
        Check-In: ${maskDate(dataEntrada)} | Check-Out: ${maskDate(dataSaida)}
      `;
    },
  },
  methods: {
    async generateReport() {
      this.loading = true;
      this.$refs.html2Pdf.generatePdf();
      this.loading = false;
    },
    getNomeParque(parqueId) {
      const parque = this.parques.find(p => p.id === parqueId);

      return parque?.nome || '-';
    },
  },
};
</script>

<style>
.ingressos-orcamento {
  font-size: 13px;
}

.ingressos-orcamento .label {
  font-size: 14px !important;
}

.group-hospedagem .field.is-grouped {
  justify-content: center;
}
</style>
